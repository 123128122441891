import { lazy } from "react";

const ProductDetail = lazy(() => import("../Components/Product"));
const Template = lazy(() => import("../Components/Template"));
const CategoryList = lazy(() => import("../Components/CategoryList"));
const Cart = lazy(() => import("../Components/Cart"));
const Login = lazy(() => import("../Components/Login"));
const Dashboard = lazy(() => import("../Components/Profile/Dashboard"));
const Orders = lazy(() => import("../Components/Profile/Orders"));
const AccountDetails = lazy(() => import("../Components/Profile/AccountDetails"));
const Address = lazy(() => import("../Components/Address"));
const Checkout = lazy(() => import("../Components/Checkout"));
const Whishlist = lazy(() => import("../Components/Whishlist"));
const OrderSuccess = lazy(() => import("../Components/OrderSuccess"));
const OrderDetails = lazy(() => import("../Components/OrderDetails"));
const LoginSuccess = lazy(() => import("../Components/LoginSuccess"));
const Faq = lazy(() => import("../Components/Faq"));
const TermsConditions = lazy(() => import("../Components/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../Components/PrivacyPolicy"));
const ComingSoon = lazy(() => import("../Components/ComingSoon"));
const AboutUs = lazy(() => import("../Components/AboutUs"));
const ContactUs = lazy(() => import("../Components/ContactUs"));
const ReturnAndExchange = lazy(() => import("../Components/ReturnAndExchange"));


export const routes = [
    {
        path: "/product/:slug",
        component: ProductDetail,
        protected: false,
    },
    {
        path: "/template",
        component: Template,
        protected: true,
    },
    {
        path: "/cart",
        component: Cart,
        protected: false,
    },
    {
        path: "/category/:slug",
        component: CategoryList,
        protected: false,
    },
    {
        path: "/login",
        component: Login,
        protected: false,
    },
    {
        path: "/checkout",
        component: Checkout,
        protected: true,
    },
    {
        path: "/wishlist",
        component: Whishlist,
        protected: true,
    },
    {
        path: "/address",
        component: Address,
        protected: true,
    },
    {
        path: "/profile",
        component: Dashboard,
        protected: true,
    },
    {
        path: "/order-success",
        component: OrderSuccess,
        protected: true,
    },
    {
        path: "/order/:slug/:id",
        component: OrderDetails,
        protected: true,
    },
    {
        path: "/orders",
        component: Orders,
        protected: true,
    },
    {
        path: "/account-details",
        component: AccountDetails,
        protected: true,
    },
    {
        path: "/login-success",
        component: LoginSuccess,
    },
    {
        component : Faq,
        path : "/faq",
        protected: false
    },
    {
        component : TermsConditions,
        path : "/terms-and-conditions",
        protected: false
    },
    {
        component : ReturnAndExchange,
        path : "/return-and-exchange",
        protected: false
    },
    {
        component : PrivacyPolicy,
        path : "/privacy-policy",
        protected: false
    },
    {
        component : AboutUs,
        path : "/about-us",
        protected: false
    },
    {
        component : ContactUs,
        path : "/contact-us",
        protected: false
    }  
    
];
