
import {SET_LOADER,SET_SNACKBAR,SET_REGION,SET_PLAN_SUBMIT,SET_PLAN_SUBMIT_ERROR} from '../actions/cart/types'

const initialState = {
    showLoader: 0,
    snackbar: {},
    hideSubmit:false,
    submitError:{}
  }
  
  const commonReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_LOADER:
        return { ...state, showLoader: action.payload  }
      case SET_SNACKBAR:
        return { ...state, snackbar: action.payload }
      case SET_REGION: 
        return {...state,region:action.payload}
      case SET_PLAN_SUBMIT:
         return {...state,hideSubmit:action.payload}
      case SET_PLAN_SUBMIT_ERROR:
          return {...state,submitError:action.payload}
      default:
        return { ...state }
    }
  }
  
  export default commonReducer