import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  const defaultMessage = encodeURIComponent('Hello Naaz Aquarium, I want to place an order.');

  const openWhatsApp = () => {
    const whatsappURL = `https://wa.me/+918898557587?text=${defaultMessage}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        background: '#25d366',
        color: '#fff',
        padding: '10px',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      onClick={openWhatsApp}
      title="Talk to us on whatsapp for your order/queries"
    >
      <FaWhatsapp size={30} />
    </div>
  );
};

export default WhatsAppButton;
