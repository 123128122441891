import React,{ useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiCall } from "./../apiCall";
import { debounce } from "lodash";


const SearchBar = (props) => {
    const {show,setShow} = props;

	// const [show, setShow] = useState(data);
	const [productList, setProductList] = useState([]);
    const [search,setSearch] = useState("")

	
    const inputChange = (value) => {
        setSearch(value)
        searchValue(value)

    }

    const searchValue = debounce(async(value) => {
        if(value){
            const result = await apiCall({url:`product/search`,method:'get',data:{search:value}})
            setProductList(result.data);
        }else{
            setProductList([])
        }
    },1000)

	
    return (
    	<section className={`input-search-wraper ${show ? "show" : null}`}>
            <div className="search-wraps">
	            <input type="text" onChange={(e)=>inputChange(e.target.value)} value = {search} placeholder="Search product"/>
				<div className="list-group">
					{productList ? productList.map((v, i) => {
                        return ( 
                            <Link key={i} onClick={()=>{setShow(!show);setSearch("");setProductList([])}} to={v.redirect_url} className="list-group-item">{v.name}</Link>
                        )}
                    ) : null}
				</div>
            </div>
		</section>
    )
}

export default SearchBar;
