
import { ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,FETCH_CART_COUNT, SET_CART_COUNT} from './types'

//add cart action
export const addToCart= (data)=>{
    return{
        type: ADD_TO_CART,
        payload:data
    }
}
//remove item action
export const removeItem=(data)=>{
    return{
        type: REMOVE_ITEM,
        payload:data
    }
}
//subtract qt action
export const fetchCartCount=(id)=>{
    return{
        type: FETCH_CART_COUNT,
    }
}
//add qt action
export const addQuantity=(data)=>{
    return{
        type: ADD_QUANTITY,
        payload:data

    }
}

export const setCartCount = (data)=>{
    return{
        type: SET_CART_COUNT,
        payload:data

    }
}