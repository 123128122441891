import { useDispatch, useSelector } from 'react-redux';
import { Spinner} from "reactstrap";

const SpinnerLoader = ()=>{
    const {showLoader} = useSelector((state)=>state.common);

    if(showLoader){
        return(
            <div className = "spinner-div" style = {{position:'absolute',left:'50%',top:'50%'}}>
                <Spinner
                    color="dark"
                    size=""
                    type="grow"
                    >
                    Loading...
                    </Spinner>
            </div>
        
        )
    }else{
        return null
    }
  
}

export default SpinnerLoader