import React, { Suspense , lazy} from "react";
import PageNotFound from "../Components/PageNotFound";
import { routes } from "./routes";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Layout from "../layout/Layout";

import Protected from "./Protected";

const Home = lazy(() => import("../Components/Home"));
const ProductDetail = lazy(() => import("../Components/Product"));
const Template = lazy(() => import("../Components/Template"));
const CategoryList = lazy(() => import("../Components/CategoryList"));
const Cart = lazy(() => import("../Components/Cart"));
const ComingSoon = lazy(() => import("../Components/ComingSoon"));



const MainRouter = () => {

    const PageLoader = () => {
        return (
            <div className="loader-mask">
                <div className="loader">
                  <div></div>
                  <div></div>
                </div>
            </div>
        );
    };

    return (
        
        <Suspense fallback={<PageLoader />}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        {routes.map((v, k) => {
                            const Component = v.component;
                            return (
                                <Route
                                    key={k}
                                    path={v.path}
                                    element={v.protected ? <Protected><Component /></Protected> : <Component />}
                                />
                            )
                            })
                        }
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                    <Route path="/under-maintenace" element={<ComingSoon />} />
                </Routes>
            </BrowserRouter>
        </Suspense>
        
    );
};


export default MainRouter;
