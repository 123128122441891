import React,{useEffect,useState,forwardRef} from "react";
import { Link } from "react-router-dom";
import {apiCall} from '../apiCall'
import {Navbar,NavbarBrand,NavbarToggler,Collapse,NavLink,UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu,Dropdown} from "reactstrap";
import {useSelector, useDispatch} from 'react-redux';
import {fetchCartCount} from '../actions/cart/cartAction'
import { getStorage, removeStorage } from "../utils/storage";
import { setCartCount } from '../actions/cart/cartAction';
import SearchBar from '../Components/SearchBar';
import { useLocation } from "react-router-dom";



const Navigation = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [category, setcategory] = useState([]);
  const dispatch = useDispatch()
  const cartCount = useSelector(state => state.cart.cartCount);
  const [show, setShow] = useState(false);
  const location = useLocation();
  
  useEffect(()=>{
    getCategories();
    dispatch(fetchCartCount())
    let cartResult = getStorage('cartItems');
    let accessToken = getStorage('access_token')
		if(cartResult && accessToken){
			 localCartToDb(JSON.parse(cartResult));
		}
    if(accessToken){
      fetchLoginCart()
    }

  },[])

  useEffect(() => { 
    setShow(false)
    setIsOpen(false)   
  }, [location]);

  const localCartToDb = async(cartItems)=>{
		try {
			const {data} = await apiCall({
									url:'/cart/cartUpdate',
									method:'post',
									data:{
										cart_items:cartItems
									}
			})

			dispatch(setCartCount(data.cartCount))
		} catch (error) {
			dispatch(setCartCount(0))
		}

		removeStorage('cartItems')
	}

  const fetchLoginCart = async()=>{
    try {
        const {data} = await apiCall({url:'/cart',method:'get'});
        dispatch(setCartCount(data?.cartCount))
    } catch (e) {
        dispatch(0)

    }
}

  const getCategories = async () => {
    const result = await apiCall({url:'category',method:'get',data:{}})
    setcategory(result.data);
  }

  return (
    <>
      <div className="header-top">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {/* <span className="coupen-info">Additional 10% OFF ON ORDERS ABOVE RS.4999. <br/> USE CODE: PLUS10</span> */}
            </div>
            <div className="header-contact-info">
              {/*<a href="#">Track order</a>*/}
              <Link to="/contact-us">Contact us</Link>
            </div>
          </div>
        </div>
      </div>
      <Navbar
        container={true}
        className="navbar sticky-top navbar-expand-lg bg-white navbar-light"
      >
        <NavbarBrand
          href="/"
        >
          {/* <p>Naaz Aquarium</p> */}
          <img className="header-logo"  src="/frontend/images/naaz-small.jpeg" alt="logo "/>
        </NavbarBrand>
        <NavbarToggler
          className="me-2"
          onClick={() => { setIsOpen(!isOpen) }}
          onMouseEnter={()=>setShow(false)}
        />
        
        <Collapse isOpen={isOpen} navbar>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {category.map((nav, i) => {
              return (
                <div key={i}>
                {nav.categories.length > 0  ?
                  <UncontrolledDropdown inNavbar nav className={nav.categories[0].subCategories.length > 0 ? "wh-100" : ""}>
                     <DropdownToggle onMouseEnter={()=>setShow(false)} className = "capitalize" caret nav> {nav.name} </DropdownToggle>
                     <DropdownMenu>
                      {nav.categories[0].subCategories.length > 0 ?
                        nav.categories.map((category, ind) => {
                           return (
                            <li key={ind} className="dropdown">
                               <a className="nav-link capitalize" role="button">{category.name}</a>
                               <ul className="dropdown-submenu">
                                  {category.subCategories.map((subcategory, index) => {
                                    return (
                                      <li key={index}>
                                      <Link className="dropdown-item capitalize" to={"/"+subcategory.url}>{subcategory.name}</Link>
                                      </li>
                                    )
                                  })}
                               </ul>
                            </li>
                           )
                        })
                        :
                        nav.categories.map((category, ind) => {
                           return (
                            <li key={ind}><Link className="dropdown-item capitalize" to={"/"+category.url}>{category.name}</Link></li>
                           )
                        })
                      }
                     </DropdownMenu>
                  </UncontrolledDropdown>
                  :
                  <li key={i} className="nav-item">
                    <a className="nav-link capitalize" href="#">{nav.name}</a>
                  </li>
                }
                </div>
              )
            })}
            
          </ul>
        </Collapse>
        <div className="flex-child flex-right nav-right hidden-sm hidden-xs">
          <ul>
            <li className="nav-search-wrap">
              <button onClick={()=>{setShow(!show);setIsOpen(false)}} className="nav-search search-trigger">
                <i className="fa fa-search"></i>
              </button>
            </li>
            <li className="nav-whishlist">
              <Link to="/wishlist"><i className="fa fa-heart ft-20"></i></Link>
            </li>
            <li className="nav-cart">
              <Link to="/cart" className="nav-cart-icon"><span>{cartCount}</span></Link>
            </li>
            <li className="nav-user">
            <Link to="/profile"><i className="fa fa-user"></i></Link>
            </li>
          </ul>
        </div>
         
      </Navbar>
      <SearchBar show={show} setShow={setShow}/>
    </>
  );
};

export default Navigation;