import React from "react";
import { Link } from "react-router-dom";
import WhatsAppButton from "./WhatsAppButton"

const Footer = () => {
  return (
    <>
        <section className="section-wrap-md subscribe">
            {/* <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1>Stay connected</h1>
                  <p>Get behind the scenes access to campaigns, collaborations, new products and sales.</p>
                  <form className="relative newsletter-form">
                    <input type="email" className="newsletter-input" placeholder="Enter your email"/>
                    <input type="submit" className="btn btn-lg btn-dark newsletter-submit" value="Subscribe"/>
                  </form>
                </div>
              </div>
            </div>        */}
        </section>
        <footer className="footer footer-type-1">
            <div className="container">
              <div className="footer-widgets">
                <div className="row">
                  <div className="col-md-3 col-sm-12 col-xs-12 footer-middle">
                    <div className="widget footer-logo">
                      <img className="p-3" src="/frontend/images/Naaz.jpeg" alt="logo"/>
                    </div>
                  </div>

                  <div className="col-xl-2 col-md-3 col-sm-6 col-xs-12 footer-middle">
                    <div className="widget footer-links">
                      <h5 className="left-align grey">Need Help</h5>
                      <ul className="list-no-dividers">
                        <li><Link to="/about-us">About us</Link></li>
                        <li><Link to="/contact-us">Contact us</Link></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-2 col-md-3 col-sm-6 col-xs-12 footer-middle">
                    <div className="widget footer-links">
                      <h5 className="left-align grey">Your Details</h5>
                      <ul className="list-no-dividers">                  
                        <li><Link to="/profile">My account</Link></li>
                        <li><Link to="/wishlist">Wishlist</Link></li>
                        <li><Link to="/orders">Order history</Link></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-2 col-md-3 col-sm-6 col-xs-12 footer-middle">
                    <div className="widget footer-links">
                      <h5 className="left-align grey">More Info</h5>
                      <ul className="list-no-dividers">
                        <li><Link to="/terms-and-conditions">Terms and conditions</Link></li>
                        <li><Link to="/return-and-exchange">Return / Exchange T&C</Link></li>
                        <li><Link to="/privacy-policy">Privacy policy</Link></li>                    
                        {/* <li><Link to="/faq">FAQ</Link></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>    
            </div>

            <div className="bottom-footer">
              <div className="container">
                <div className="row">

                  <div className="col-sm-6 copyright sm-text-center">
                    <div className="footer-socials">
                        <div className="social-icons nobase">
                          <a href="#"><i className="fa fa-twitter"></i></a>
                          <a href="#"><i className="fa fa-facebook"></i></a>
                          <a href="#"><i className="fa fa-google-plus"></i></a>
                        </div>
                      </div>
                  </div>

                  <div className="col-sm-6 col-xs-12 footer-payment-systems text-end sm-text-center mt-sml-10">
                    <span className="me-5">Secure Payment</span>
                    <i className="fa fa-cc-paypal"></i>
                    <i className="fa fa-cc-visa"></i>
                    <i className="fa fa-cc-mastercard"></i>
                    <i className="fa fa-cc-discover"></i>
                    <i className="fa fa-cc-amex"></i> 
                  </div>

                </div>
              </div>
            </div>
            <WhatsAppButton/>
        </footer>
    </>
  );
};

export default Footer;
