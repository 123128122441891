export const setStorage = (key,value)=>{

    localStorage.setItem(key.toLowerCase(),value);
}

export const getStorage = (key)=>{

    return localStorage.getItem(key.toLowerCase());
}

export const removeStorage = (key)=>{

    return localStorage.removeItem(key.toLowerCase());
}