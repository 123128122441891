import cartReducer from './cartReducer';
import commonReducer from './commonReducer';
import {combineReducers} from 'redux';

//Combine all the sub reducers
const rootReducer = combineReducers({
    cart: cartReducer,
    common:commonReducer
})

export default rootReducer