import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './reducers/rootReducer';

import { GoogleOAuthProvider } from '@react-oauth/google';


const store = createStore(rootReducer)
const clientId = "364675216664-5mudt00pkamq5sl0rcr6q5frb9c37o5b.apps.googleusercontent.com"
// 382585640125-2j09ego96ofbduc61ophcl28ofqme9aq.apps.googleusercontent.com
ReactDOM.render(
<Provider store={store}>
		<App />
</Provider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
