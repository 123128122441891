import {setStorage, getStorage} from '../storage'

export const uniqueIdGenerator = (productData = {})=>{
    if(Object.keys(productData).length){
        let uniqueId = productData.title
        Object.keys(productData.selectedVariant || {}).map((x)=>{
                uniqueId+= `${x}${productData.selectedVariant[x]}`
        })
        return uniqueId;

    }

    return null
}

export const recentlyViewd = (productData = {})=>{
    let productList = getStorage('recent_products')
    if(productList){
        productList = JSON.parse(productList);
        if(productList.length == 8){
            productList.splice(0,1);
        }
        const findIndex = productList?.findIndex((x)=>x.slug == productData.slug);
        if(findIndex < 0){
            productList.push(productData);
            setStorage('recent_products',JSON.stringify(productList.reverse()))
        }
    }else{
        setStorage('recent_products',JSON.stringify([productData]))
    }
}

export const isAutthenticated = ()=>{
        return getStorage('access_token');
}