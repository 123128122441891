import { ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,FETCH_CART_COUNT, SET_CART_COUNT } from '../actions/cart/types'
import {setStorage, getStorage} from '../utils/storage';
import {uniqueIdGenerator} from '../utils/helpers'
const cartItem = JSON.parse(localStorage.getItem('cartItems'));

const cartcount = cartItem ? cartItem.cartItems.length : 0;

const initState = {
    cartCount: cartcount,
}
 
const cartReducer = (state = initState, action) => {
    switch (action.type) {
        case 'INCREMENT_COUNT':
        return {...state,
            cartCount:action.payload.cartCount      
        }
        case 'DECREMENT_COUNT':
        return {...state,
            cartCount:state.cartCount-1      
        }
        case FETCH_CART_COUNT :
            const [cartCount] = initialStorageData(action.payload);
            return {
                ...state,
                cartCount:cartCount.length
            }
        case ADD_TO_CART:
            const [addStorage,addUniqueId,addFindData,addIndex,productData] = initialStorageData(action.payload);
            if(!addFindData){
                productData.uniqueId = addUniqueId
                addStorage.push(productData)
                setStorage('cartItems',JSON.stringify(addStorage))
            }
            return {
                ...state,
                cartCount:addStorage.length
            }
        case ADD_QUANTITY:
            const cartQuantityCopy = JSON.parse(getStorage('cartItems') || []) || []
            const productQuantityData = action.payload
            let id = uniqueIdGenerator(productQuantityData);
            if(id){
                const findIndex = cartQuantityCopy.findIndex(item=> item.uniqueId === id);
                if(findIndex >= 0){
                    const {operator, noOfQuantity} = productQuantityData
                    if(noOfQuantity){
                        cartQuantityCopy[findIndex].qty = noOfQuantity
                    }else if(operator == "+"){
                        cartQuantityCopy[findIndex].qty = cartQuantityCopy[findIndex].qty + 1
                    }else{
                        cartQuantityCopy[findIndex].qty = cartQuantityCopy[findIndex].qty - 1
                    }

                    // if(cartQuantityCopy[findIndex].qty == 0){
                    //     cartQuantityCopy.splice(findIndex,1);
                    // }

                    setStorage('cartItems',JSON.stringify(cartQuantityCopy))
                }
            }
            return {
                ...state,
                cartCount:cartQuantityCopy.length

            }
        case REMOVE_ITEM:
            const [removeStorage,removeUniqueId,removeFindData,removeIndex] = initialStorageData(action.payload);
            removeStorage.splice(removeIndex,1);
            setStorage('cartItems',JSON.stringify(removeStorage))
            return {
                ...state,
                cartCount:removeStorage.length

            }
        case SET_CART_COUNT:
            return {
                ...state,
                cartCount:action.payload
            }
        default:
        return state
    }
}


const initialStorageData = (payload)=>{
    const cartStorage = getStorage('cartItems')
    const cartCopy = cartStorage ? JSON.parse(cartStorage) : [];
    const productData = payload
    let uniqueId = uniqueIdGenerator(productData);
    const findData = cartCopy.find(item=> item.uniqueId === uniqueId)
    const findIndex = cartCopy.findIndex(item=> item.uniqueId === uniqueId)
    return [cartCopy,uniqueId,findData,findIndex,productData]
}


 
export default cartReducer;