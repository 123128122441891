import React from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import {
  Outlet
} from "react-router-dom";
import SpinnerLoader from "../Components/Loader";

const Layout = props => {

    return (
    	<>
    	<Navigation />
        <div className="content-wrapper">
            <SpinnerLoader/>
            <Outlet />
            <Footer />
        </div>
        </>
    );
};

export default Layout;
