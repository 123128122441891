export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const FETCH_CART_COUNT = 'FETCH_CART_COUNT';
export const SET_CART_COUNT = 'SET_CART_COUNT';
export const SET_LOADER = 'SET_LOADER'
export const SET_SNACKBAR = 'SET_SNACKBAR'
export const SET_REGION = "SET_REGION"
export const SET_PLAN_SUBMIT = "SET_PLAN_SUBMIT"
export const SET_PLAN_SUBMIT_ERROR = "SET_PLAN_SUBMIT_ERROR"