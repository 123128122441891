import React from 'react'

export default function PageNotFound() {
    return (
        <section className="section-wrap page-404">
	        <div className="container">
	          <div className="row text-center">
	            <div className="col-md-6 col-md-offset-3">
	              <h1>404</h1>
	              <h2 className="mb-20">Page Not Found</h2>
	            </div>
	          </div>
	        </div>
	    </section>
    )
}
