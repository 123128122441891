import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { getStorage, setStorage, removeStorage } from '../utils/storage';

const Protected = ({ children }) => {
	const prevLocation = useLocation();
	const accessToken = getStorage('access_token');
	if (!accessToken) {
		return <Navigate to={`/login?redirectTo=${prevLocation.pathname}`} replace />;
	}
	return children;
};

export default Protected;